import { Title } from '@solidjs/meta';
import { Calendar } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { StyleCard } from '../_style-card';

export default function CalendarPage() {
	return (
		<>
			<Title>Calendar | Components | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Calendar</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Calendar">
					<div>
						<Calendar
							timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
							minDate={dayjs()}
							selectedDate={dayjs()}
						/>
					</div>
				</StyleCard>
			</div>
		</>
	);
}
